import { groupDataByWeek } from "./groupTimeReportDataByWeek";
import checkForHoliday from "./checkForHoliday";
import groupTimeReportDataByMonth from "./groupTimeReportDataByMonth";
import convertToXmlStructure from "./convertToXmlStructure";
import groupAbsenceDataForTimeReport from "./groupAbsenceDataForTimeReport";
import getPeriodWeeks from "./getPeriodWeeks";
import handleWeekendsAndHolidays from "./handleWeekendsAndHolidays";
import createRegularPayObject from "./CreateRegularPayObject";
import handleReimbursements from "./handleReimbursements";
import createFreeHolidayObject from "./createFreeHolidayObject";
import { createCompObject2 } from "./createCompObject2";
import removeAbsenceDataOutsidePeriod from "./removeAbsenceDataOutsidePeriod";
import calculateTotalWorkHoursForMonthEmployees from "./calculateTotalWorkHoursForMonthEmployees";
import handleMissingHours from "./handleMissingHours";
import handleUnreportedCompensationHours from "./handleUnreportedCompensationHours";
import extractATFAbsenceHours from "./extractATFAbsenceHours";
import calculateQualifyingPeriod from "./calculateQualifyingPeriod";
import isASingleUserSelectedForExport from "./isASingleUserSelectedForExport";
import createEmptyWeekDataForMonthEmploymentUsers from "./createEmptyWeekDataForMonthEmploymentUsers";
import checkForRegisteredAbsenceOptimized from "./checkForRegisteredAbsenceOptimized";

/**
 * This file prepares the incoming data for export. It structures the data, and also recalucates the data to make sure it is
 * following the correct rules for the export according to the Byggskollektivavtal.
 * 
 * Comments is added to functions which is not self-explanatory
 * 
 * 
 * 
 * @param {Array} data - The primary data to be structured.
 * @param {Array} entireWeekData - Data related to the entire week.
 * @param {Date} startDate - Start date of the period.
 * @param {Date} endDate - End date of the period.
 * @returns {Array} - The structured data ready for export.
 */

async function structureDataForExport(
  data,
  entireWeekData,
  startDate,
  endDate,
  usersList
) {

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  };

  /** 
   *  If there is no specific user selected for the report. We need to add all of the 
   *  monthly users which have not reported any data, otherwise these users will not be included in the export.
   * 
   *  We want to have all of the users in the export, even if they have not reported any data.
   */
  if (!isASingleUserSelectedForExport(data)) {
      var monthEmploymentUsers = usersList;
      //Remove any employees that already have reported data
      monthEmploymentUsers = monthEmploymentUsers.filter((user) => {
        return (
          user.employee_number !== undefined && // Exclude users with undefined employment_number
          user.employee_number !== "");
      });
      var emptyWeekDataForMonthEmploymentUsers = createEmptyWeekDataForMonthEmploymentUsers(monthEmploymentUsers, startDate, endDate, getWeekNumber(startDate));
      
      data = data.concat(emptyWeekDataForMonthEmploymentUsers);
      
  }

  /**
   * 
   * Next up, we gather swedish holidays from the holiday API. We will use this data to add holidays salary, any extra pay etc.
   * 
   */

  // Get all the holidays for a specific year and country code "se"
  const holidays = await checkForHoliday("se", data[0].date.slice(0, 4));
  
  // Extract dates from the holidays array
  const holidayDates = holidays.map((holiday) => holiday.date);

  // Filter holidays by specific names
  const freeDays = holidays.filter((holiday) =>
    [
      "Epiphany",
      "Good Friday",
      "Easter Monday",
      "may 1st",
      "Feast of the Ascension of Jesus Christ",
      "Pentecost",
      "All Saints day",
      "Christmas Day",
      "Boxing Day",
      "New Year's Day",
      "Christmas Eve",
      "New Year's Eve",
      "Easter",
      "Midsummer Eve",
      "National Day",
    ].includes(holiday.name)
  );

  // Group data by week while considering holidays and entire week data
  const groupedData = groupDataByWeek(
    data,
    holidayDates,
    entireWeekData,
    startDate,
    endDate
  );

  const restructureData = [];

  // Get the week periods
  const weekPeriod = getPeriodWeeks(groupedData[0].week, startDate);

  // Extract unique user IDs
  const uniqueUserIds = new Set();
  groupedData.forEach((item) => {
    uniqueUserIds.add(item.user_id);
  });
  const uniqueUserIdsArray = Array.from(uniqueUserIds);

  // Initialize absence arrays
  //let absenceArray = [];
  let matchingAbsence = [];
  // let checkAbsenceType = "rapports";
  // Populate the absence array by iterating through week periods and user IDs
  // TODO: FOR OPTIMIZATION PURPOSES, IS IT POSSIBLE TO GATHER ALL OF THE ABSENCE DATA IN ONLY 1 DB CALL?, THIS WOULD REDUCE THE TIME OF THE EXPORT
  /*for (const week of weekPeriod) {
    for (const userId of uniqueUserIdsArray) {
      const weekObject = {
        week: week,
        user_id: userId,
        year: groupedData[0].year,
      };

      checkAbsenceType = "hours";
      const absence = await checkForRegisteredAbsence(
        weekObject,
        checkAbsenceType,
        startDate,
        endDate
      );
      
      if (absence) {
        absenceArray.push(absence);
      }
    }
  }*/

  
  let absenceArray = await checkForRegisteredAbsenceOptimized('hours', startDate, endDate, uniqueUserIdsArray, weekPeriod,groupedData[0].year); 

  

  /**
   * This is the main loop where we iterate through the grouped data. This is where we loop through every week for each user to recalucate the data, 
   * add eventual compensations, handle missing hours, handle reimbursements etc.
   * 
   * Notice how the object restructureData is populated with data for each iteration.
   */
  for await (const week of groupedData) {
    if (week.employment_number === '184') {
      console.log("Take me here")
    }
    // Initialize vars
    let overTimeWithoutCompensation = 0;
    var ATFHours = 0;
    let totalAbsenceHours = 0;

    // Find absences which is matching the week and user ID
    var findMatchingAbsence = absenceArray.find(
      (absence) =>
        parseInt(absence.week) === parseInt(week.week) &&
        parseInt(absence.user_id) === parseInt(week.user_id)
    );

    /** 
     * We now have all of the absence data, as well as all of the timereport data for the week.
     * When a user has reported work hours, and become sick the same day. The day should count as a Qulifying day (Karensdag in Swedish).
     * 
     * Therefore, we need to change the reported workday as 0 hours (keeping the report as there might be reimbursements that should be counted).
     * The worked hours, is then changed to compensation hours (löneart 850), as the user should be compensated for the worked hours, and not have 
     * been working for free. Also what happens, is that the absence report is raised to 8 hours to count as a qualifying day.
     *
     * In short:
     * - Add 8 hours to the absence report
     * - Change the worked hours to 0
     * - Change the worked hours to compensation hours
     * 
     * If you have any questions, please contact Susanne at JIAB Ställningsmontage.
     */
    
    calculateQualifyingPeriod(week, findMatchingAbsence, restructureData);
    
    /**
     * Now we are going to recalculate the total reported hours for the week, as well as extracting absence hours for calculations later on.
     * This part is quite self explanatory.
     */
    let totalReportedHours = week.total_work_hours + week.total_work_hours_outside_span;
    if (findMatchingAbsence) {
      if (findMatchingAbsence.entries.length > 0) {
        ATFHours = extractATFAbsenceHours(findMatchingAbsence, week);
      }
      // If a matching absence is found, add to the matchingAbsence array
      matchingAbsence.push(findMatchingAbsence);

      // If absence is found, add its hours to the total reported hours
      if (findMatchingAbsence.year > 0) {
        totalAbsenceHours += findMatchingAbsence.total_absence_hours;
        totalReportedHours += findMatchingAbsence.total_absence_hours;
      }

      /** 
       * This function might not be needed, but it is here for future reference or if the customer wants to change the way the hours are calculated.
       */
      // If there is days where the employee is sick, or VABar, the user has reported the expected hours vab or illness for the day. However, when JIAB is calculating the hours for the week,
      // the hours of illness or VAB should be calculated as a full day if it is the only hours that has been reported for that day. This is because the hours should be right on the lön
      // specification.
      //var updatedAbsence = handleSickLeaveAndVABHours(week, findMatchingAbsence);
      //matchingAbsence.push(updatedAbsence);
     
    }

    /**
     * If the user is eligible for free days, we need to calculate the hours for the free days.
     * A user is eligible for free days if they have 8 hours the closes workday before, or after the free day.
     * 
     */
    var freeDayHours = await createFreeHolidayObject(week, freeDays, restructureData);
    if (freeDayHours > 0) {
      totalAbsenceHours += freeDayHours;
      totalReportedHours += freeDayHours;
    }

    /**
     *  Update week's total reported hours
     */ 
    week.total_reported_hours = totalReportedHours;

    /**
     * If a user have been working on a holiday, or a weekend, he or she be compensated for the hours worked.
     */
    var holidayHours = handleWeekendsAndHolidays(week.holidays, 313, week.employment_type, restructureData, week.employment_number, week);
    var weekendHours = handleWeekendsAndHolidays(week.weekEnds, 204, week.employment_type, restructureData, week.employment_number, week);

    /**
     *  Next up, we handle reimbursements for the week, this is: Reseersättning, Traktamente och Utryckning
     *  We add this to the restructureData object.
     */ 
    handleReimbursements(week, restructureData);

    /**
     * If the employee have been working overtime, we create a compensation object for the hours worked.
     */
    if ( week.comp_30 > 0 || week.comp_50 > 0 || week.comp_70 > 0 || week.comp_100 > 0) {
      overTimeWithoutCompensation = createCompObject2(week, restructureData);
    }

    /**
     * If the employee is have the employment type "month" we are going to see how many hours that are supposed to be worked during this
     * week, and also remove any absence hours from those hours, to be able to calculate how many hours the monthly
     */ 
    if (week.employment_type === 'month') {
      var availableHoursForWork = 0;
      if (week.start_date < startDate.toISOString().slice(0,10)) {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(startDate.toISOString().slice(0,10), week.end_date);
      }
      else if (week.end_date > endDate.toISOString().slice(0,10)) {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(week.start_date, endDate.toISOString().slice(0,10));
      } else {
        availableHoursForWork = calculateTotalWorkHoursForMonthEmployees(week.start_date, week.end_date);
      }
      
      week.total_work_hours += availableHoursForWork - totalAbsenceHours;
    }

    /**
     * If the user has worked more than 40 hours, but havn't reported any overtime hours,
     * add extra hours as compensation hours. No special case if its reported on weekend or holiday
     */
    var numberOfHoursToRegisterAsNonRegistredComp = 0;
    [, numberOfHoursToRegisterAsNonRegistredComp] = handleUnreportedCompensationHours(week, restructureData, freeDayHours, startDate, endDate, numberOfHoursToRegisterAsNonRegistredComp, ATFHours);

    /**
     * Now we create the regular pay object for the week, which is the worked hours. This is reported to either 031 or 010, depending on the employment type.
     */
    createRegularPayObject(week, restructureData, overTimeWithoutCompensation, holidayHours, weekendHours, numberOfHoursToRegisterAsNonRegistredComp);

    /**
     * Lastly, we need to check if the total reported hours is less than 40, if so, we need to calculate and adjust for missing hours.
     * These should be reported at löneart 33.
     */ 
    if (week.employment_type !== 'month') {
      handleMissingHours(week, restructureData, freeDays, totalReportedHours);
    }
    
  }


  /**
   * WHEN DATA XML DATA IS PREPARED
   */

  // Group the restructured data by month
  var newData = groupTimeReportDataByMonth(restructureData, startDate, endDate);

  // If matching absences exist, convert and group them, then append to the new data
  if (matchingAbsence) {
    matchingAbsence = removeAbsenceDataOutsidePeriod(startDate, endDate, matchingAbsence);
    matchingAbsence = await convertToXmlStructure(matchingAbsence);
    
    matchingAbsence = groupAbsenceDataForTimeReport(matchingAbsence);
    matchingAbsence.forEach((absence) => {
      newData.push(absence);
    });
  }

  newData.sort((a, b) => {
    if (a.employmentId < b.employmentId) {
      return -1;
    }
    if (a.employmentId > b.employmentId) {
      return 1;
    }
    if (a.periodStartDate < b.periodStartDate) {
      return -1;
    }
    if (a.periodStartDate > b.periodStartDate) {
      return 1;
    }
    if (a.quantity < b.quantity) {
      return -1;
    }
    if (a.quantity > b.quantity) {
      return 1;
    }
    return 0;
  });

  return newData;
}

export default structureDataForExport;



