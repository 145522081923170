export const convertWorkingHoursToDHFormat = (totalHours) => {
    if (totalHours === 0) {
        return 0;
    }
    let days = Math.floor(totalHours / 8);
    let hours = totalHours % 8;

    let parts = [];
    if (days > 0) {
        parts.push(`${days}d`);
    }
    if (hours !== 0) {
        parts.push(`${hours}h`);
    }

    return parts.join(" ");
}