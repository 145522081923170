import handleMissingHours from "../exportUtils/handleMissingHours";
import {groupDataByWeek} from "../exportUtils/groupTimeReportDataByWeek";
import {getNewestTimeReport, getOldestTimeReport} from "../getDatesFromTimeReports";

export const addPayTypeId33ForMonthlyReports = async (reports, freeDays, holidayDates) => {
    // This method takes in a list of reports for a SINGLE user
    // 1. Loop through the reports and save the week numbers to a list called "weeks"
    const weeks = [...new Set(reports.map(report => report.week))];

    // 2. Loop through the "weeks" list and for each week loop through all the reports with the current week
    for (let week of weeks) {
        let totalWorkHours = 0;
        let weekReports = [];
        reports.forEach(report => {
            if (report.employment_type !== "month" && report.week === week) {
                // 2.1 For all the reports in a week sum their work hours attribute and print it
                totalWorkHours += report.work_hours !== undefined ? parseFloat(report.work_hours) : 0;

                weekReports.push(report)
            }
        });

        if (weekReports.length > 0){
            const combinedReport = groupDataByWeek(weekReports, holidayDates, [], getOldestTimeReport(weekReports).date, getNewestTimeReport(weekReports).date)[0];
            // if (combinedReport.employment_number === "46") {
            //     console.log("combined:", combinedReport);
            //     console.log("!weekreplen:", week);
            //     // totalWorkHours += combinedReport.total_work_hours;
            //     console.log("!weekTotwork:", totalWorkHours)
            // }
            let restructureData = [];
            handleMissingHours(combinedReport, restructureData, freeDays, totalWorkHours);
            if (restructureData.length > 0) {
                // if (combinedReport.employment_number === "46") {
                //     console.log("combinedResa:", restructureData);
                // }
                for (let data of restructureData) {
                    const resdataToReportFormat = {
                        periodStartDate: data.periodStartDate ? data.periodStartDate : null,
                        periodEndDate: data.periodEndDate ? data.periodEndDate : null,
                        id: null,
                        isPayTypeId33: true,
                        missing_hours: data.quantity,
                        week: week,
                        employment_number: weekReports[0].employment_number
                    }
                    weekReports.push(resdataToReportFormat);
                    reports.push(resdataToReportFormat);
                }
            }

        }
    }

    return reports;
}