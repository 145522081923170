import React, { useEffect, useState } from "react";
import MonthCalendarPrintable from "../components/Calendar/CalendarPrintable";
import CalendarHeaderPrintable from "../components/Calendar/CalendarHeaderPrintable";
import Select from "react-select";
import "../styles/CalendarViewPrintable.css";
import { fetchWithTimeout } from "../utils/fetchWithTimeout";
import { getAllUserNames } from "../utils/renderUtils/getAllUserNames";
import { calculateWorkHours } from "../utils/calenderViewUtils/CalculateCalendarHours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {addPayTypeId33ForMonthlyReports} from "../utils/handleReportsUtils/addPayTypeId33";
import checkForHoliday from "../utils/exportUtils/checkForHoliday";

const CalendarPrintView = () => {
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [users, setUsers] = useState([]);
	const [allUserReports, setAllUserReports] = useState({});
    const [allUserCalulcatedData, setAllUserCalculatedData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
    const [employeeNumbers, setEmployeeNumbers] = useState([]);
    const months =
        [
            { label: "Januari", value: "01" },
            { label: "Februari", value: "02" },
            { label: "Mars", value: "03" },
            { label: "April", value: "04" },
            { label: "Maj", value: "05" },
            { label: "Juni", value: "06" },
            { label: "Juli", value: "07" },
            { label: "Augusti", value: "08" },
            { label: "September", value: "09" },
            { label: "Oktober", value: "10" },
            { label: "November", value: "11" },
            { label: "December", value: "12" },
        ];


	// Add leading zero to month if needed
	const addZeroToMonth = (month) => (month < 10 ? "0" + month : month);

	useEffect(() => {
		// Fetch all user names
		const fetchData = async () => {

			const usersData = await getAllUserNames();
            var tempEmployeeNumbers = [];
            for (let i = 0; i < usersData.length; i++) {
                if (usersData[i].employee_number == null || usersData[i].employee_number === "" || usersData[i].employee_number === undefined) {
                    continue;
                } else {
                    tempEmployeeNumbers.push(usersData[i].employee_number);
                }

            }
            setEmployeeNumbers(tempEmployeeNumbers);

            await fetchAllUserReports(tempEmployeeNumbers);

			setUsers(usersData);
		};
		fetchData();
	}, [selectedMonth, selectedYear]);

    const findUser = (property, value) => {
        return users.find(user => user[property] === value);
    };

    const findSummationByEmployeeNumber = (employee_number) => {
        return allUserCalulcatedData[employee_number] || null; // Return the summation or null if not found
    };


	// Fetch data for all users at once
	const fetchAllUserReports = async (tempEmployeeNumbers) => {
		setIsLoading(true);
		const customerURL = localStorage.getItem("customerUrl");
		const userToken = localStorage.getItem("token");

		// Initialize an empty object to store each user's reports
		let userReports = {};
		// Fetch time reports for each user
		const url_get_all_user_report_for_month = `${customerURL}/wp-json/tidig/calendar/v1/get_timereports_for_all_users_by_month`;
        const requestBody = JSON.stringify({
                month: selectedMonth,   // Replace with the actual month
                year: selectedYear,  // Replace with the actual year
                users: tempEmployeeNumbers // Replace with the array of user IDs
            });
        await fetchWithTimeout(url_get_all_user_report_for_month, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
                "Access-Control-Allow-Origin": "*",
            },
            body: requestBody,
        })
        .then((response) => response.json())
        .then(async (responseJson) => {
            setAllUserCalculatedData(responseJson.summations);
            const holidays = await checkForHoliday("se", new Date().getFullYear());

            // Extract dates from the holidays array
            const holidayDates = holidays.map((holiday) => holiday.date);

            // Filter holidays by specific names
            const freeDays = holidays.filter((holiday) =>
                [
                    "Epiphany",
                    "Good Friday",
                    "Easter Monday",
                    "may 1st",
                    "Feast of the Ascension of Jesus Christ",
                    "Pentecost",
                    "All Saints day",
                    "Christmas Day",
                    "Boxing Day",
                    "New Year's Day",
                    "Christmas Eve",
                    "New Year's Eve",
                    "Easter",
                    "Midsummer Eve",
                    "National Day",
                ].includes(holiday.name)
            );

            for (const key in responseJson.time_reports) {
                const newReports = await addPayTypeId33ForMonthlyReports(responseJson.time_reports[key], freeDays, holidayDates);
                responseJson.time_reports[key] = newReports;
            }
            setAllUserReports(responseJson.time_reports);
        });

		setIsLoading(false);
	};

    const findMissingHoursForEmployee = (reports, summarizedData) => {
        if (!reports || reports.length < 1) {
            return 0;
        }
        // SummarizedMissingHours is the raw amount of missing hours.
        // NOTE: Absence has not been taken into consideration yet.
        let summarizedMissingHours = 0;

        for (let report of reports) {
            if (report.isPayTypeId33 === true) {
                summarizedMissingHours+= report.missing_hours;
            }
        }

        // Here we take raw amount of missing hours and subtract it by the amount of submitted absence hours, to get the amount of missed reported hours
        summarizedMissingHours = summarizedMissingHours - convertStringFormatToFloatHours(summarizedData.formattedAbsenceHours);

        return summarizedMissingHours > 0 ? summarizedMissingHours : 0;
    }

	// Print all calendars at once
	const handlePrintAllCalendars = async () => {
		//await fetchAllUserReports();
		window.print(); // Print the consolidated view
	};

    const convertStringFormatToFloatHours = (timeString) => {
        // example timestring: "3:45h" (3h 45 min)
        let [hours, minutes] = timeString.slice(0, -1).split(':');

        hours = parseInt(hours);
        minutes = parseInt(minutes);

        return hours + (minutes / 60);
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#F2F5FA",
            border: "none",
            borderRadius: "10px",
            height: "51px",
            width: "259px",
            margin: "5px",
        }),
    };

	return (
		<div className="mainContainer">
			<div className="HeaderContainer">
				<div className="Header">
					<header>Kalender</header>
				</div>
                <div className="Tab"></div>
			</div>
            <div className="subHeaderContainerCalendar">
                <div className="dateSelectFilter">
                    <div className="dateSelectCalendarContainer">
                        <p className="subHeaderText">År</p>
                        <div className="filterContainerCalendar">
                            <Select
                                placeholder="Välj år"
                                options= {[
                                    { value: '2027', label: '2027' },
                                    { value: '2026', label: '2026' },
                                    { value: '2025', label: '2025' },
                                    { value: '2024', label: '2024' },

                                ]}
                                value={{ value: selectedYear, label: selectedYear }}
                                onChange={(selectedOption) => setSelectedYear(selectedOption.value)}
                                styles={colourStyles}

                            />
                        </div>
                    </div>
                    <div className="dateSelectCalendarContainer">
                        <p className="subHeaderText">Månad</p>
                        <div className="filterContainerCalendar">
                            <Select
                                placeholder="Välj månad"
                                options={months}
                                styles={colourStyles}
                                value={{ value: selectedMonth, label: months[selectedMonth - 1].label }}
                                onChange={(selectedOption) => setSelectedMonth(selectedOption.value)}

                            />
                        </div>
                    </div>

                    <button id="exportButton" className="button" onClick={handlePrintAllCalendars}>
                        {isLoading ? <FontAwesomeIcon icon={faSpinner} size="lg" color="white" /> : <FontAwesomeIcon icon={faPrint} size="lg" color="white" />}
                    </button>
                </div>


            </div>

			<div id="all-calendars-printable">
				{Object.keys(allUserReports).map((employment_number, index) => (
					<div key={index} className="calendar-for-user">
						<CalendarHeaderPrintable
                            name={findUser("employee_number", employment_number) ? findUser("employee_number", employment_number).full_name : ""}
                            employment_number={employment_number}
                            numberOfReports= {allUserReports[employment_number].filter(report => !report.hasOwnProperty("isPayTypeId33")).length}
                            missingHours={findMissingHoursForEmployee(allUserReports[employment_number], allUserCalulcatedData[employment_number])}
                            totalHours={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).formattedWorkHours : 0}
                            travelExpenses={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).totalDaysWithTravelExpenses : 0}
                            exclamation={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).totalDaysOutCall : 0}
                            allowance={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).totalDaysAllowance : 0}
                            overtime16_17={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).overtime16_17 : 0}
                            overtime17_19={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).overtime17_19 : 0}
                            overtime19_22={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).overtime19_22 : 0}
                            overtime22_05={findSummationByEmployeeNumber(employment_number) ? findSummationByEmployeeNumber(employment_number).overtime22_05 : 0}

                       />
						<MonthCalendarPrintable
							events={allUserReports[employment_number].map((report) => ({
								title: report.absence_type
									? `${calculateWorkHours(report.start_time, report.stop_time, 0, report.absence_type)} ${report.absence_type}`
									: calculateWorkHours(report.start_time, report.stop_time, report.break_time),
								start: new Date(report.date),
								end: new Date(report.date),
								type: report.absence_type ? "absence" : "timereport",
							}))}
                            selectedDate={new Date(selectedYear, selectedMonth - 1, 1)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default CalendarPrintView;
