import * as XLSX from "xlsx";
import structureDataForExport from "../exportUtils/structureDataForExport";

const exportToExcel = async (data, entireWeekData, startDate, endDate, isLoading, setIsLoading, usersList,selectedUser) => {

  // Om en användare är vald och det inte finns någon data för den användaren
  
  if ( selectedUser !== 0 && data.length === 0) {
    console.log("Selected user has no data for this week");
  }
  const structuredData = await structureDataForExport(data, entireWeekData, startDate, endDate, usersList);

  const groupedByEmploymentId = structuredData.reduce((acc, item) => {
    const { employmentId } = item;
    if (!acc[employmentId]) {
      acc[employmentId] = []; // Initialize an array for this employmentId
    }
    acc[employmentId].push(item);
    return acc;
  }, {});

  const sheetData = [
    ["Anställningsnummer", "Namn", "Löneart.", "Antal", "Startdatum", "Slutdatum"], // Header row
  ];
  
  // Convert grouped data to an array and sort by user names
  const sortedEntries = Object.entries(groupedByEmploymentId).sort(([idA], [idB]) => {
    const userA = usersList.find((user) => user.employee_number === idA);
    const userB = usersList.find((user) => user.employee_number === idB);
    const nameA = userA?.label || ""; // Fallback to empty string if no label
    const nameB = userB?.label || ""; // Fallback to empty string if no label
    return nameA.localeCompare(nameB, "sv"); // Sort using Swedish locale
  });
  // Process sorted data
  sortedEntries.forEach(([employmentId, records]) => {
    records.forEach((record) => {
      // Find label based on employmentId in usersList
      const user = usersList.find((user) => user.employee_number === employmentId);
      if (user && user.label) {
        sheetData.push([employmentId, user.label, record.payTypeId, record.quantity, record.periodStartDate, record.periodEndDate]);
      }
    });
    // Add a blank row after each employee's records
    sheetData.push([]);
  });
  
  // Convert data to a sheet
  const sheet = XLSX.utils.aoa_to_sheet(sheetData);
  
  // Create a new workbook and append the sheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, "Employee Data");
  
  // Write the workbook to a file
  XLSX.writeFile(workbook, "Grouped_Employment_Data.xlsx");
  setIsLoading(false);

  /*console.log("structuredData", structuredData);


  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(rearrangedRows);
  XLSX.utils.book_append_sheet(workbook, sheet, "Sheet 1");


  XLSX.writeFile(workbook, "exported-data.xlsx");
  setIsLoading(false);*/
  
  
  
  
  
  /*const period = getPeriodWeeks(data[0].week, data[0].date);
  const startAndEndDates = getStartAndEndDates(period, data[0].year);
  if (absenceReports) {
    absenceReports.map((item) => {
      // format data
      item.date = item.date.slice(0, 10);
      item.start_time = item.start_time.slice(0, 5);
      item.stop_time = item.stop_time.slice(0, 5);
      item.user_id = parseInt(item.user_id);

      usersList.map((user) => {
        user.value = parseInt(user.value);
        if (item.user_id === user.value) {
          // add the username to the item
          item.user_name = user.label;
        } else {
          // do nothing
        }
      });
    });
  }

  let headers = Object.keys(data[0]);

  // Sort headers with priority
  const priorityHeaders = [
    "user_name",
    "user_id",
    "date",
    "week",
    "start_time",
    "stop_time",
    "work_hours",
    "break_time",
    "comp_30",
    "comp_50",
    "comp_70",
    "comp_100",
    "has_allowance",
    "has_travel_expences",
    "out_call",
  ];
  
  headers.sort((a, b) => {
    if (priorityHeaders.includes(a) && priorityHeaders.includes(b)) {
      return priorityHeaders.indexOf(a) - priorityHeaders.indexOf(b);
    }
    if (priorityHeaders.includes(a)) {
      return -1;
    }
    if (priorityHeaders.includes(b)) {
      return 1;
    }
    return 0;
  });

  // Rearrange each row according to the sorted headers
  const rearrangedRows = data.map((row) => {
    return headers.map((h) => row[h]);
  });

  // Sort rows by user_id and then date within each user_id
  rearrangedRows.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;

    let dateA = new Date(a[2]);
    let dateB = new Date(b[2]);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
  });

  rearrangedRows.unshift(headers);

  // Fetch and format the absence reports
  if (absenceReports.length > 0 && path != "absence") {
  const absenceHeaders = Object.keys(absenceReports[0]);

  // Set absence headers priority
  const absencePriorityHeaders = [
    "user_name",
    "user_id",
    "date",
    "week",
    "start_time",
    "stop_time",
    "absence_type",
    "break_time",
    "comp_30",
    "comp_50",
    "comp_70",
    "comp_100",
    "has_allowance",
    "has_travel_expences",
    "out_call",
  ];
  
  // Sort headers with priority
  absenceHeaders.sort((a, b) => {
    if (absencePriorityHeaders.includes(a) && absencePriorityHeaders.includes(b)) {
      return absencePriorityHeaders.indexOf(a) - absencePriorityHeaders.indexOf(b);
    }
    if (absencePriorityHeaders.includes(a)) {
      return -1;
    }
    if (absencePriorityHeaders.includes(b)) {
      return 1;
    }
    return 0;
  });

  const rearrangedAbsenceRows = absenceReports.map((row) => {
    return absenceHeaders.map((h) => row[h]);
  });

  // Sort rows by user_id and then date within each user_id
  rearrangedAbsenceRows.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;

    let dateA = new Date(a[2]);
    let dateB = new Date(b[2]);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    return 0;
  });

  // Add a separator and a title for absence reports
  rearrangedRows.push(new Array(headers.length).fill('')); // Adding a blank row
  rearrangedRows.push(['Frånvaro']); // Adding the title
  rearrangedRows.push(new Array(headers.length).fill('')); // Adding another blank row

  // Add the headers for the absence reports
  rearrangedRows.push(absenceHeaders);

  // Add the absence reports data
  rearrangedAbsenceRows.forEach((row) => rearrangedRows.push(row));
}

  const workbook = XLSX.utils.book_new();


  const sheet = XLSX.utils.aoa_to_sheet(rearrangedRows);
  XLSX.utils.book_append_sheet(workbook, sheet, "Sheet 1");


  XLSX.writeFile(workbook, "exported-data.xlsx");
  setIsLoading(false);*/
};

export default exportToExcel;
