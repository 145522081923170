export const getOldestTimeReport = (timeReports) => {
    timeReports.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate - bDate;
    });

    return timeReports[0];
}

export const getNewestTimeReport = (timeReports) => {
    timeReports.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate - bDate;
    });

    return timeReports[timeReports.length - 1];
}