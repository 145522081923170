import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import exportToExcel from "../utils/exportUtils/exportToExcel";
import { useExportXml } from "../utils/useExportXml";
import "../styles/ExportButton.css";

const ExportButton = ({
  exportData,
  usersList,
  entireWeekData,
  requestDataArray,
  path,
  startDate,
  endDate,
  selectedUser
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const { exportXml } = useExportXml();

  if (exportData !== undefined) {
    exportData.map((item) => {
      // format data
      if (item) {
        if (item.date && item.date.length > 10) {
          item.date = item.date.slice(0, 10);
        }
        if (item.start_time && item.start_time.length > 5) {
          item.start_time = item.start_time.slice(0, 5);
        }
        if (item.stop_time && item.stop_time.length > 5) {
          item.stop_time = item.stop_time.slice(0, 5);
        }
        item.user_id = parseInt(item.user_id);
        if (usersList) {
        usersList.map((user) => {
          user.value = parseInt(user.value);
          if (item.user_id === user.value) {
            // add the username to the item
            item.user_name = user.label;
          } else {
            // do nothing
          }
        });
      }
      }
    });
  }
  return (
    <div>
      <button id="exportButton" className="button" onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faFileExport} size="lg" color="white" />
      </button>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            {error && <h3>{error}</h3>}
            {!error && <h3>Välj Exportmål</h3>}
            <div className="button-container">
              <button
                id="exportXMLButton"
                className="export-button"
                onClick={() => exportXml(exportData, entireWeekData, startDate, endDate, isLoading, setIsLoading, usersList) + setIsLoading(true)}
              >
                Export till Hogia &nbsp;
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : null}

              </button>


            </div>
            <div className="button-container">
              <button
                className="exportExcel-button"
                onClick={() =>
                  exportToExcel(
                    exportData,
                    entireWeekData,
                    startDate,
                    endDate,
                    isLoadingExcel,
                    setIsLoadingExcel,
                    usersList,
                    selectedUser
                  ) +
                  setIsLoadingExcel(true)
                }
              >
                Export till Excel &nbsp;
                {isLoadingExcel ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
              </button>
            </div>
            <button
              id="cancel-button"
              className="cancel-button"
              onClick={() => {
                setIsModalOpen(false);
                setError("");
                setIsLoading(false);
                setIsLoadingExcel(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportButton;
