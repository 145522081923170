import { fetchWithTimeout } from "../fetchWithTimeout";

const fetchUserData = async (newStartDate, endDate, requestDataArray) => {
  // format startDate and endDate to YYYY-MM-DD
  const end = new Date(endDate).toISOString().slice(0, 10);
  const customerURL = localStorage.getItem("customerUrl");
  const userToken = localStorage.getItem("token");
  let request = "";
  if (requestDataArray && typeof requestDataArray[0] === "number") {
    // if id is a integer, add it to the request
    request = request + "&id=" + requestDataArray[0];
  }
  // If the id is equal to 0, fetch all users
  if (requestDataArray && requestDataArray[0] === 0) {
    request = "";
  }
  var url =
    customerURL +
    "/wp-json/tidig/v1/gettimereports2" +
    "?startDate=" + newStartDate +
    "&endDate=" + end +
    request;
  const data = await fetchWithTimeout(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      let data = responseJson.req;
      return data;
    })
    .catch((error) => {
      console.error(error, "this is the error");
    });
  return await data.data;
};

export { fetchUserData };
