// CustomToolbar.js
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../styles/Printable.css';
import {
    faArrowLeft,
    faArrowRight,
    faCalendar,
    faCalendarDay,
    faClockRotateLeft,
    faMugHot
} from '@fortawesome/free-solid-svg-icons';

const CalendarToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };

    const goToToday = () => {
        toolbar.onNavigate('TODAY');
    };

    const label = () => {
        const date = toolbar.date;
        const view = toolbar.view;
        return view === 'month' ? date.toLocaleDateString('default', { month: 'long', year: 'numeric' }) : '';
    };

    return (
        <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          <FontAwesomeIcon icon={faArrowLeft}  />
        </button>
        <button type="button" onClick={goToToday}>
          <FontAwesomeIcon icon={faCalendarDay} />
        </button>
        <button type="button" onClick={goToNext}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </span>
            <span className="rbc-toolbar-label">{label()}</span>
        </div>
    );
};

export default CalendarToolbar;
