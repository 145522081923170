import moment from 'moment';


const getNumberOfFreeDaysThisWeek = (week, freeDays, restructureData) => {
    const weekDates = generateWeekDates(week.start_date, week.end_date);
    // const entriesDates = [...week.entries, ...week.holidays, ...week.weekEnds].map(entry => entry.date);

    let quantity = 0;
    for (const freeDay of freeDays) {
        // Innehåller veckan en ledig dag och har inte personen jobbat den lediga dagen?
        if (weekDates.includes(freeDay.date)) {
            const weekdayNumeric = parseInt(freeDay.weekday.date.numeric, 10);
            if (weekdayNumeric >= 1 && weekdayNumeric <= 5) {  // Check if free day falls on a weekday
                quantity += 1;
            }
        }
    }
    return quantity;
}

// This helper function will generate an array of date strings for each day in the given date range.
function generateWeekDates(start, end) {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];

    while (startDate <= endDate) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'days');
    }

    return dates;
}


export default getNumberOfFreeDaysThisWeek;