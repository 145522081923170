import React, {useEffect, useState} from "react";
import Select from "react-select";
import "../../styles/CalendarView.css";
import "../../styles/Printable.css";
import {faCalendar, faClockRotateLeft, faMugHot, faPlus, faPrint} from '@fortawesome/free-solid-svg-icons';
import NewReportModal from "../../components/NewReportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {week} from "react-big-calendar/lib/utils/dates";

const CalendarHeader = ({
    users,
    setSelectedUser,
    numberOfReports = 0,
    totalHours = "0:00h",
    missingHours = "0:00h",
    totalBreak = "0:00h",
    travelExpenses = 0,
    allowance = 0,
    exclamation = 0,
    overtime16_17 = 0,
    overtime17_19 = 0,
    overtime19_22 = 0,
    overtime22_05 = 0,
    toggleModal,
}) => {
    const [selectedUserName, setSelectedUserName] = useState(undefined);
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#F2F5FA",
            border: "none",
            borderRadius: "10px",
            height: "51px",
            width: "259px",
            margin: "5px",
        }),
    };


    // useEffect(() => {
    //     // Log the users state after it has been updated
    //     console.log('Users state updated:', users);
    //     console.log('Type of users:', typeof users);
    //     console.log('Is users an array in header:', Array.isArray(users));
    // }, [users]);

    return (
        <>
            <div className="subHeaderContainerCalendar">
                <div className="userFilter">
                    <p className="subHeaderText">ANVÄNDARE</p>
                    <div className="filterContainerCalendar">
                        <Select
                            className="userSelect"
                            placeholder="Användare"
                            options={users}
                            styles={colourStyles}
                            onChange={(e) => {
                                setSelectedUserName(e.label);
                                setSelectedUser(e);
                            }}
                        />
                        <span
                            style={{ display: 'none' }}
                            className="userFilterUserName"
                        >{selectedUserName}</span>
                    </div>
                </div>
                <div className="buttonContainer">
                    <button
                        style={{display: selectedUserName !== undefined ? 'block' : 'none'}}
                        className="button"
                        onClick={() => window.print()}
                    >
                        <FontAwesomeIcon icon={faPrint} size="lg" color="white"/>
                    </button>
                    <button
                        className="button"
                        onClick={toggleModal}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" color="white"/>
                    </button>
                </div>
                <div className="informationBlock">
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">Antal rapporter:</p>
                            <p className="infoHeaderText">Timmar:</p>
                            <p className="infoHeaderText">Ej rapporterad tid:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{numberOfReports}</p>
                            <p className="infoText">{totalHours}</p>
                            <p className="infoText">{missingHours}</p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">Reseersättning:</p>
                            <p className="infoHeaderText">Utryckning:</p>
                            <p className="infoHeaderText">Traktamente:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{travelExpenses}</p>
                            <p className="infoText">{exclamation}</p>
                            <p className="infoText">{allowance}</p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">ÖT 16-17:</p>
                            <p className="infoHeaderText">ÖT 17-19:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{overtime16_17}</p>
                            <p className="infoText">{overtime17_19}</p>
                        </div>
                    </div>
                    <div className="informationContainer">
                        <div className="detailedInfoContainer">
                            <p className="infoHeaderText">ÖT 19-22:</p>
                            <p className="infoHeaderText">ÖT 22-05:</p>
                        </div>
                        <div className="detailedDataContainer">
                            <p className="infoText">{overtime19_22}</p>
                            <p className="infoText">{overtime22_05}</p>
                        </div>
                    </div>
                </div>
</div>


</>
    )
}

export default CalendarHeader;