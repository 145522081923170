import moment from "moment/moment";
import {getNewestTimeReport, getOldestTimeReport} from "../getDatesFromTimeReports";
import structureDataForExport from "../exportUtils/structureDataForExport";

const calculateWorkHours = (startTime, endTime, breakTime, absenceType) => {
    const format = 'HH:mm:ss';
    let start = moment(startTime, format);
    let end = moment(endTime, format);

    if (end.isBefore(start)) {
        end.add(1, 'day');
    }

    const duration = moment.duration(end.diff(start));
    const totalMinutes = duration.asMinutes();

    
    // If this is a report of absence, then we need to check if the total minutes is 
    // over 240 (4h), if so, then a breakTime of 60min should be added, otherwise not.
    
    if (absenceType && totalMinutes > 240) {
        breakTime = 60;
    }


    let workMinutes = totalMinutes - breakTime;

    // Zero out negative work minutes
    if (workMinutes < 0) {
        workMinutes = 0;
    }

    const hours = Math.floor(workMinutes / 60);
    const minutes = workMinutes % 60;

    // Format the result as "HH:MMh"
    const formattedWorkHours = `${hours}:${minutes.toString().padStart(2, '0')}h`;

    return formattedWorkHours;
};

const calculateTotalDataForMonth = async (reports, selectedUser, entireWeekData = []) => {
    let totalWorkMinutes = 0;
    let totalAbsenceMinutes = 0;
    let missingHours = 0;
    let overtime16_17 = 0;
    let overtime17_19 = 0;
    let overtime19_22 = 0;
    let overtime22_05 = 0;
    let totalDaysWithTravelExpenses = 0;
    let totalDaysAllowance = 0;
    let totalDaysOutCall = 0;

    for (let report of reports) {
        const format = 'HH:mm:ss';
        let start = moment(report.start_time, format);
        let end = moment(report.stop_time, format);

        if (report.absence_type) {
            console.log("has absence report")
            if (end.isBefore(start)) {
                end.add(1, 'day');
            }

            const duration = moment.duration(end.diff(start));
            const totalMinutes = duration.asMinutes();
            let absenceMinutes = totalMinutes;

            // Zero out negative work minutes
            if (absenceMinutes < 0) {
                absenceMinutes = 0;
            }
            totalAbsenceMinutes += absenceMinutes;
        } else {
            if (report.has_travel_expences === "1" || report.has_travel_expences === 1) {
                totalDaysWithTravelExpenses++;
            }
            if (report.has_allowance === "1" || report.has_allowance === 1) {
                totalDaysAllowance++;
            }
            if (report.out_call === "1" || report.out_call === 1) {
                totalDaysOutCall++;
            }
            overtime16_17 += parseInt(report.comp_30);
            overtime17_19 += parseInt(report.comp_50);
            overtime19_22 += parseInt(report.comp_70);
            overtime22_05 += parseInt(report.comp_100);

            if (end.isBefore(start)) {
                end.add(1, 'day');
            }

            const duration = moment.duration(end.diff(start));
            const totalMinutes = duration.asMinutes();
            let workMinutes = totalMinutes - report.break_time;

            // Zero out negative work minutes
            if (workMinutes < 0) {
                workMinutes = 0;
            }
            totalWorkMinutes += workMinutes;
        }

    }

    const filteredReports = reports.filter(obj => !( 'absence_type' in obj ));
    const newData = await structureDataForExport(filteredReports, entireWeekData, getOldestTimeReport(reports).date, getNewestTimeReport(reports).date, [selectedUser.value]);

    // console.log("newDataPay33:", newData.filter(obj => obj.payTypeId === 33));
    const payTypeId33 = newData.filter(obj => obj.payTypeId === 33);
    const sumMissingHours = payTypeId33.reduce((total, current) => total + current.quantity, 0);
    const workHours = Math.floor(totalWorkMinutes / 60);
    const workMinutes = totalWorkMinutes % 60;
    const absenceHours = Math.floor(totalAbsenceMinutes / 60);
    const absenceMinutes = totalAbsenceMinutes % 60;

    // Format the result as "HH:MMh"
    const formattedWorkHours = `${workHours}:${workMinutes.toString().padStart(2, '0')}h`;
    const formattedAbsenceHours = `${absenceHours}:${absenceMinutes.toString().padStart(2, '0')}h`;

    return [formattedWorkHours, sumMissingHours ? sumMissingHours : 0, formattedAbsenceHours, totalDaysAllowance, totalDaysOutCall, totalDaysWithTravelExpenses, overtime16_17, overtime17_19, overtime19_22, overtime22_05];

}



export { calculateWorkHours, calculateTotalDataForMonth }
