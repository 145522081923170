import React, {use, useRef, useState} from 'react';
import Select from 'react-select';


const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "#F2F5FA",
        border: "none",
        borderRadius: "10px",
        height: "51px",
        width: "259px",
        overflowY: "auto"
    }),
};

const UserSelect = ({ users, requestDataArray, setRequestDataArray, userFilter, setSelectedUser, setSelectedUsers, handleRender }) => {
    const selectRef = useRef();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selected) => {
        setSelectedOption(selected);

        if (selected.length < 1) {
            setSelectedUsers([]);

        } else if (selected.some(obj => obj.label.toLowerCase() === "all")) {
            setSelectedOption(null);
            setSelectedUsers([]);

        } else {
            setSelectedUsers(selected);
        }

        handleRender();
    }

    return (
        <div className="userFilter">
            <div className="userFilterSubContainer">
                <p className="subHeaderText">ANVÄNDARE</p>
                <div className="filterContainer">
                    <Select
                        isMulti
                        isClearable
                        placeholder="Användare"
                        options={users}
                        styles={colourStyles}
                        ref={selectRef}
                        value={selectedOption}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserSelect;