import getNumberOfFreeDaysThisWeek from "./getNumberOfFreeDaysThisWeek";
import { getWeekDates } from "./getWeekDates";
import { findCostCenter } from "./payTypeIdIndex";

const handleMissingHours = (week, restructureData, freeDays, totalReportedHours) => {
    if (totalReportedHours < 40) {
        // The number of hours a worker is mandatory to work varies depending if there is a free day during monday to friday.
        // Therefore we need to count how many free days exists during the week, and take the 40 usually mandatory work hours.
        const number_of_free_days_this_week = getNumberOfFreeDaysThisWeek(week, freeDays);
        const number_of_hours_to_reduce_from_mandatory_work_hours = number_of_free_days_this_week * 8;
        const missingHoursDates = getWeekDates(week.start_date);
        const missingHours = 40 - number_of_hours_to_reduce_from_mandatory_work_hours - totalReportedHours - week.total_comp_hours || 0 - week.total_holiday_hours || 0 - week.total_weekend_hours || 0;

        if (missingHours > 0) {
          const payTypeId = 33;
          restructureData.push({
            periodStartDate: missingHoursDates.first ? missingHoursDates.first : null,
            periodEndDate: missingHoursDates.last ? missingHoursDates.last : null,
            quantity: missingHours,
            employmentId: week.employment_number,
            payTypeCode: "L",
            payTypeId: payTypeId,
            costCenter: findCostCenter(payTypeId, week.employment_type),
          });
        }
      }
    return restructureData;
}

export default handleMissingHours;